
























































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { Form } from "element-ui";
import { uuid } from "@/tool";
import TestPaperDialog from "./test-paper-dialog.vue";
import {
  setResourceTemplateProfile,
  getResourceTemplateProfile,
  getTestPaperGroupGradeOptionList,
  getTestPaperGroupList,
} from "@/api/testPaper";
import {
  TestPaperGroup,
  ResourceTemplateProfile,
  TestPaperGroupGradeOption,
  TestPaper,
} from "@/tool/_class";
import { appTitle } from "@/utils/public-consts";
import * as _ from "lodash";

class TestPaperGroupItem extends TestPaperGroup {}

@Component({
  name: "ResourceTemplateEditor",
  components: {
    cardBox,
    TestPaperDialog,
  },
})
export default class extends Vue {
  private displayType: string = "view";
  private testPaperGroups: TestPaperGroup[] = [];
  private pageType: string = "";
  private activeTabName: string = "testPaper";
  private grades: TestPaperGroupGradeOption[] = [];
  private title: string = "新增资源模板";
  private type(): string {
    if (this.pageType === "add") {
      this.title = "新增资源模板";
      this.$route.meta.title = "新增资源模板";
      document.title = `新增资源模板 | ${appTitle}`;
      return "立即创建";
    }
    if (this.pageType === "edit") {
      this.title = "编辑资源模板";
      this.$route.meta.title = "编辑资源模板";
      document.title = `编辑资源模板 | ${appTitle}`;
      return "保 存";
    }
    return "立即创建";
  }
  private ruleForm: ResourceTemplateProfile = new ResourceTemplateProfile();
  private rules = {};

  private loading: boolean = false;

  private gradeFormatter(row: any, column: any) {
    const idx = this.grades.findIndex((item: any) => {
      return item.gradeNo == row.gradeNo;
    });
    if (idx >= 0) {
      return this.grades[idx].grade;
    } else {
      return row.grade;
    }
  }

  private multipleSelection: TestPaper[] = [];
  handleSelectionChange(val: TestPaper[]) {
    this.multipleSelection = val;
  }

  private handleTestPaperConfirmed(
    testPapers: TestPaper[],
    cancelList: TestPaper[]
  ) {
    
    for (let i = 0; i < cancelList.length; i++) {
      const testPaper = cancelList[i];
      const idx = this.ruleForm.testPaperList.findIndex((item) => {
        return item.testPaperId == testPaper.testPaperId;
      });
      if (idx >= 0) {
        this.ruleForm.testPaperList.splice(idx, 1);
      }
    }
    for (let i = 0; i < testPapers.length; i++) {
      const testPaper = testPapers[i];
      const idx = this.ruleForm.testPaperList.findIndex((item) => {
        return item.testPaperId == testPaper.testPaperId;
      });
      if (idx < 0) {
        let item = {
          testPaperId: testPaper.testPaperId,
          resourceTemplateId: this.ruleForm.resourceTemplateId,
          testPaperName: testPaper.testPaperName,
          grade: testPaper.grade,
          term: testPaper.term,
          term02: testPaper.term02,
        };
        this.ruleForm.testPaperList.push(item);
        //
        if (
          !this.testPaperGroups.some((item) => {
            return (
              item.grade == testPaper.grade &&
              item.term == testPaper.term &&
              item.term02 == testPaper.term02
            );
          })
        ) {
          const group = new TestPaperGroup();
          _.merge(
            group,
            _.pick(item, [
              "gradeNo",
              "grade",
              "termNo",
              "term",
              "termNo2",
              "term02",
            ])
          );
          this.testPaperGroups.push(group);
        }
      }
    } //for
  }

  private async doDeleteTestPaperClick(testPaperId: string) {
    const idx = this.ruleForm.testPaperList.findIndex((testPaper) => {
      return testPaper.testPaperId == testPaperId;
    });
    if (idx < 0) {
      return;
    }
    const testPaper = this.ruleForm.testPaperList[idx];
    await this.$confirm(
      `删除“${testPaper.testPaperName}”授权，是否继续？`,
      "提示"
    );
    this.ruleForm.testPaperList.splice(idx, 1);
    const idx1 = this.multipleSelection.findIndex((testPaper) => {
      return testPaper.testPaperId == testPaperId;
    });
    if (idx >= 0) {
      this.multipleSelection.splice(idx1, 1);
    }
  }

  private async doDeleteSelectdTestPaperClick() {
    await this.$confirm("清除已选试卷授权，是否继续？", "提示");
    for (let i = 0; i < this.multipleSelection.length; i++) {
      const testPaper = this.multipleSelection[i];
      const idx = this.ruleForm.testPaperList.findIndex(
        (item: { testPaperId: string }) => {
          return testPaper.testPaperId == item.testPaperId;
        }
      );
      if (idx >= 0) {
        this.ruleForm.testPaperList.splice(idx, 1);
      }
    }
    this.multipleSelection.splice(0, this.multipleSelection.length);
  }

  private async doDeleteAllTestPaperClick() {
    await this.$confirm("清除所有试卷授权，是否继续？", "提示");
    this.ruleForm.testPaperList.splice(0, this.ruleForm.testPaperList.length);
    this.multipleSelection.splice(0, this.multipleSelection.length);
  }

  private selectedTestPaperCount(testPaperGroup: TestPaperGroup): number {
    return this.ruleForm.testPaperList.filter((item) => {
      return (
        item.grade == testPaperGroup.grade &&
        item.term == testPaperGroup.term &&
        item.term02 == testPaperGroup.term02
      );
    }).length;
  }

  private doAddTestPaperClick() {
    (this.$refs.testPaperDialog as TestPaperDialog).show(
      {
        gradeNo: "",
        term: "",
        term02: "",
      },
      this.ruleForm.testPaperList.map((item) => item.testPaperId)
    );
  }

  private doGotoTestPaperListPageClick(testPaperGroup: TestPaperGroup) {
    (this.$refs.testPaperDialog as TestPaperDialog).show(
      {
        gradeNo: testPaperGroup.gradeNo,
        term: testPaperGroup.term,
        term02: testPaperGroup.term02,
      },
      this.ruleForm.testPaperList.map((item) => item.testPaperId)
    );
  }

  private testPaperGroupSelectedIndex: number = -1;
  private doTestPaperGroupClick(testPaperGroup: TestPaperGroup, index: number) {
    //alert(`test:${index}`);
    this.testPaperGroupSelectedIndex = index;
    this.multipleSelection.splice(0, this.multipleSelection.length);

    const testPapers: any[] = this.ruleForm.testPaperList.filter((item) => {
      return (
        item.grade == testPaperGroup.grade &&
        item.term == testPaperGroup.term &&
        item.term02 == testPaperGroup.term02
      );
    });
    this.multipleSelection.push(...testPapers);
  }

  private async submitForm() {
    try {
      this.loading = true;
      const valid = await (this.$refs.ruleForm as Form).validate();
      if (valid) {
        await setResourceTemplateProfile(this.ruleForm);
        this.$message({
          message: this.pageType === "add" ? "添加成功" : "保存成功",
          type: "success",
        });
      }
    } finally {
      this.loading = false;
    }
  }
  private doGoBackClick() {
    this.$router.go(-1);
  }

  private get filteredTestPaperGroups(): TestPaperGroup[] {
    return this.testPaperGroups.filter((item) => {
      return this.selectedTestPaperCount(item) > 0;
    });
  }

  private async __initTestPaperGroup(resourceTemplateId: string) {
    const res = await getTestPaperGroupList(
      _.merge({
        curPage: 1,
        pageSize: 99999,
        resourceTemplateId,
      })
    );
    this.testPaperGroups = res.data.items;
  }
  private async __init() {
    try {
      this.loading = true;
      this.grades.splice(0, this.grades.length);
      const { data } = await getTestPaperGroupGradeOptionList();
      data.items.forEach((element: TestPaperGroupGradeOption) => {
        this.grades.push(element);
      });

      const resourceTemplateId: string =
        (this.$route.query.resourceTemplateId as string) || "";
      if (resourceTemplateId) {
        this.__initTestPaperGroup(resourceTemplateId);
        getResourceTemplateProfile(resourceTemplateId).then((res: any) => {
          this.ruleForm = res.data;
        });
      }
      this.pageType = this.$route.query.type as string;
    } finally {
      this.loading = false;
    }
  }
  mounted() {
    this.__init();
  }
}
